import React, {useEffect, useState} from 'react'
import { MDBRow, MDBCol } from "mdbreact";
import {FormattedMessage, useIntl} from 'react-intl';
import SelectFieldIntl from '../../../../SelectFieldIntl'
import ReactSelectAllGrouped from "../../../../ReactSelectAllGrouped";
import {uniquePropVals, uniquePropValsGetter} from "../../../../../utils/utils";

const allOption = { value: "*", msgId: "filter.multi.option.dropdown.all.selector"}
const countryDefaultOption = {value:"", msgId: "any.label"}

const multiSelectProps = {
    className:"react-select-container",
    classNamePrefix:"react-select",
    isMulti:true,
    closeMenuOnSelect:false,
    hideSelectedOptions:false,
    allowSelectAll:true,
    isSearchable:false,
    intlLabel: true,
    backspaceRemovesValue:false,
    placeholder:<FormattedMessage id={"react-select.select-your-filters"}/>
}

export default function AccountFilterCriteria ({accountList, setFilter, filter, defaultFilter, setDefaultFilter, planView }){
    const intl = useIntl();
    const [countryOptions, setCountryOptions]= useState([])
    const [planTypeOptions, setPlanTypeOptions]= useState([])
    const [businessUnitOptions, setBusinessUnitOptions]= useState([])

    useEffect(()=>{
        let countries = uniquePropValsGetter(accountList, (obj)=>obj.merchant.countryCode).map((country)=>{
            let text = `country-item.${country}`
            return {
                value: text,
                label: intl.formatMessage({ id:text }), 
                msgId: text
            }
        })
        countries.sort((a, b) => a.label.localeCompare(b.label));
        countries.unshift(countryDefaultOption)
        setCountryOptions(countries)
        if(planView){
            let planTypes = uniquePropVals(accountList, 'accountType').map((type)=>{
                let text = `plan.plan-type.${type.toLowerCase()}`
                return {value: text, msgId: text}
            })
            planTypes.unshift(allOption)
            setPlanTypeOptions([{
                msgId: "react-select.select-your-filters",
                options: planTypes
            }])
            setDefaultFilter({planType: planTypes})
        } else {
            let businessUnits = uniquePropVals(accountList, 'paymentType').map((type)=>{
                let text = `account.payment-type.${type.toLowerCase()}`
                return {value: text, msgId: text}
            })
            businessUnits.unshift(allOption)
            setBusinessUnitOptions([{
                msgId: "react-select.select-your-filters",
                options: businessUnits
            }])
            setDefaultFilter({businessUnit: businessUnits})
        }
    }, [])

    return(
        <React.Fragment>
            <MDBRow>
                <MDBCol size={"12"} className={"mt-3 mt-sm-0"}  key={"enrollCountry"}>
                    <SelectFieldIntl
                        name={"enrollCountry"}
                        id={"enrollCountry"}
                        value={filter?.country ?? defaultFilter?.country}
                        selectOptions={countryOptions}
                        label={<FormattedMessage id={"ups.enroll.country.label"}/>}
                        setLabelBefore={true}
                        onChange={(e)=>setFilter("country", e.target.value)}
                    />
                </MDBCol>
                <MDBCol size={"12"} key={"field-type"}>
                    {planView
                        ?  <ReactSelectAllGrouped
                            label={intl.formatMessage({id:"manage-plans-table.main.plan-type"})}
                            inputId={"planType"}
                            options={planTypeOptions}
                            onChange={(selected)=>setFilter("planType", selected)}
                            value={filter?.planType ?? defaultFilter?.planType}
                            {...multiSelectProps}
                        />
                        :  <ReactSelectAllGrouped
                            label={intl.formatMessage({id:"businessUnit.label"})}
                            inputId={"businessUnit"}
                            options={businessUnitOptions}
                            onChange={(selected)=>setFilter("businessUnit", selected)}
                            value={filter?.businessUnit ?? defaultFilter?.businessUnit}
                            {...multiSelectProps}
                        />
                    }
                </MDBCol>
            </MDBRow>
        </React.Fragment>
    )
}